import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import Helmet from "react-helmet";
import MediaQuery from "react-responsive";

import Layout from "../../components/Layout";
import LevelRoll from "../../components/LevelRoll";
import LevelSelect from "../../components/LevelSelect";

import planetOne from "../../img/planet1.svg";
import meteorOne from "../../img/meteor.svg";

import "./style.sass";

const LevelIndexPage = ({ data, currentPageUrl }) => {
  const { edges: posts } = data.allMarkdownRemark;
  const {
    mobileLogo,
    desktopLogo,
    groundFloorDesktop,
    groundFloorMobile
  } = data;
  const allLevels = posts.map(post => post.node.frontmatter.price.toString());
  const levelRange = Array.from(new Set(allLevels));

  return (
    <Layout currentPageUrl={currentPageUrl} pageName="levels-page">
      <Helmet>
        <title>{`Level Listing | 100 Story Building`}</title>
        <meta
          name="description"
          content="Here's a list of our available levels"
        />
        {/* <!-- Twitter Card data --> */}
        <meta
          name="twitter:title"
          content={`Level Listing | 100 Story Building`}
        />
        <meta
          name="twitter:description"
          content="Here's a list of our available levels"
        />

        {/* <!-- Open Graph data --> */}
        <meta
          property="og:title"
          content={`Level Listing | 100 Story Building`}
        />
        <meta
          property="og:description"
          content="Here's a list of our available levels"
        />
        <meta property="og:type" content="article" />
      </Helmet>
      <section className="top">
        <div className="planet-one spinning">
          <img src={planetOne} alt="" />
        </div>
        <div className="meteor-one shooting">
          <img src={meteorOne} alt="" />
        </div>
        <div className="meteor-two shooting">
          <img src={meteorOne} alt="" />
        </div>
        <div className="meteor-three shooting">
          <img src={meteorOne} alt="" />
        </div>
        <div className="meteor-four shooting">
          <img src={meteorOne} alt="" />
        </div>
        <div className="dot dot-one" />
        <div className="dot dot-two" />
      </section>

      <section className="ground-level">
        <div className="logo">
          <MediaQuery query="(min-width: 769px)">
            <Img
              fluid={desktopLogo.childImageSharp.fluid}
              alt="100 Story Building"
            />
          </MediaQuery>
          <MediaQuery query="(max-width: 768px)">
            <Img
              fluid={mobileLogo.childImageSharp.fluid}
              alt="100 Story Building"
            />
          </MediaQuery>
        </div>
        <MediaQuery query="(min-width: 769px)">
          <Img
            className="gf"
            fluid={groundFloorDesktop.childImageSharp.fluid}
          />
        </MediaQuery>
        <MediaQuery query="(max-width: 768px)">
          <Img className="gf" fluid={groundFloorMobile.childImageSharp.fluid} />
        </MediaQuery>
      </section>

      <section className="levels">
        <div className="level-intro">
          <h2>
            We have a Level for every price range so there's no need for a bank
            loan here. Each Level even comes with its own special key and
            Owner's Deed (for real).
          </h2>
        </div>
        <section className="section">
          <div className="container-fluid">
            <div className="content">
              <LevelRoll data={data} />
            </div>
          </div>
        </section>
      </section>

      <LevelSelect levelRange={levelRange} />
    </Layout>
  );
};

export default ({
  location: { href = "https://forsale.100storybuilding.org.au" }
}) => {
  return (
    <StaticQuery
      query={graphql`
        query LevelRollQuery {
          desktopLogo: file(
            relativePath: { eq: "static/main-logo-desktop.png" }
          ) {
            childImageSharp {
              fluid(maxWidth: 700, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          mobileLogo: file(
            relativePath: { eq: "static/main-logo-mobile.png" }
          ) {
            childImageSharp {
              fluid(maxWidth: 375, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          groundFloorDesktop: file(
            relativePath: { eq: "static/level-list-ground-floor-desktop.png" }
          ) {
            childImageSharp {
              fluid(maxWidth: 2880, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          groundFloorMobile: file(
            relativePath: { eq: "static/level-list-ground-floor-mobile.png" }
          ) {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          allMarkdownRemark(
            sort: {
              order: DESC
              fields: [frontmatter___price, frontmatter___level]
            }
            filter: { frontmatter: { templateKey: { eq: "level-post" } } }
          ) {
            edges {
              node {
                excerpt(pruneLength: 125)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  templateKey
                  date(formatString: "MMMM DD, YYYY")
                  level
                  sqm
                  bathrooms
                  wildcardcount
                  wildcardicon {
                    relativePath
                  }
                  price
                  owner
                  thumbnailimage {
                    childImageSharp {
                      fluid(maxWidth: 270, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => (
        <LevelIndexPage data={data} currentPageUrl={href} />
      )}
    />
  );
};
