import React, { useState, useEffect, useRef } from "react";

import "./style.sass";

const findClosestLevel = () => {
  let closestPosition = 20000;
  let closestElement = null;
  [].slice
    .call(document.querySelectorAll("[data-level-ref]"))
    .reverse()
    .forEach((levelElement, i) => {
      const { top } = levelElement.getBoundingClientRect();
      if (top > 100) {
        return;
      }
      if (i === 0) {
        closestElement = levelElement;
        closestPosition = top;
      }
      if (top === 0) {
        closestElement = levelElement;
        closestPosition = top;
      } else if (top > 0 && top <= Math.abs(closestPosition)) {
        closestElement = levelElement;
        closestPosition = top;
      } else if (top < 0 && -top < Math.abs(closestPosition)) {
        closestElement = levelElement;
        closestPosition = top;
      }
    });

  return closestElement;
};

const LevelSelect = ({ levelRange }) => {
  // State for storing mouse coordinates
  const [currentTier, setLevel] = useState("20000");

  const updateLevelState = () => {
    if (typeof window === "undefined") {
      return;
    }

    const closestElement = findClosestLevel();

    const newTier =
      closestElement && closestElement.getAttribute("data-level-ref");
    if (newTier && levelRange.includes(newTier) && newTier !== currentTier) {
      return setLevel(newTier);
    }
  };

  // Add event listener using our hook
  useEffect(() => {
    return updateLevelState();
  }, []);

  useEventListener("wheel", () => {
    return updateLevelState();
  });

  useEventListener("touchmove", () => {
    return updateLevelState();
  });

  useEventListener("touchend", () => {
    updateLevelState();
    const updateTimeout = setTimeout(() => {
      updateLevelState();
    }, 600);

    return () => clearTimeout(updateTimeout);
  });

  useEventListener("keydown", ({ keyCode }) => {
    if (keyCode === 40 || keyCode === 38) {
      return updateLevelState();
    }
  });

  const handleClick = newTier => {
    if (
      typeof document !== "undefined" &&
      newTier &&
      levelRange.includes(newTier) &&
      currentTier !== newTier
    ) {
      const nl = document.getElementById(newTier);
      if (nl) {
        nl.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
        });
      }

      return setLevel(newTier);
    }
  };

  const indexPosition = levelRange.indexOf(currentTier);
  const previousHash = levelRange[indexPosition - 1] || levelRange[0];
  const nextHash =
    levelRange[indexPosition + 1] || levelRange[levelRange.length - 1];

  return (
    <div className="level-select">
      <div className="inner">
        <div className="left">
          <div className="text">Price&nbsp;Tier</div>
          <div className="price">
            {new Intl.NumberFormat("en-AU", {
              style: "currency",
              currency: "AUD",
              minimumFractionDigits: 0
            }).format(currentTier)}
          </div>
        </div>
        <div className="right">
          <div>
            <button
              aria-label="Previous Price"
              onClick={() => handleClick(previousHash)}
            >
              <i className="uparrow" />
            </button>
          </div>
          <div>
            <button
              aria-label="Next Price"
              onClick={() => handleClick(nextHash)}
            >
              <i className="downarrow" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Hook
function useEventListener(eventName, handler, element = global) {
  // Create a ref that stores handler
  const savedHandler = useRef();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    // Make sure element supports addEventListener
    const isSupported = element && element.addEventListener;
    if (!isSupported) {
      return;
    }

    // Create event listener that calls handler function stored in ref
    const eventListener = event => savedHandler.current(event);

    // Add event listener
    element.addEventListener(eventName, eventListener);

    // Remove event listener on cleanup
    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]); // Re-run if eventName or element changes
}

export default LevelSelect;
