import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import PreviewCompatibleImage from "../../PreviewCompatibleImage";

import soldTag from "../../../img/sold-tag.svg";
import sqmIcon from "../../../img/sqm-icon.svg";
import bathIcon from "../../../img/bath-icon.svg";

import "./style.sass";

const LevelCard = ({
  marker,
  post: {
    fields: { slug },
    frontmatter: {
      thumbnailimage,
      title,
      price,
      level,
      sqm,
      bathrooms,
      wildcardcount,
      wildcardicon,
      owner
    }
  }
}) => {
  return (
    <div className="level-card">
      {marker ? <div id={marker} data-level-ref={marker} /> : null}
      <Link to={slug}>
        <div className="top-body">
          <div className="post-meta">
            <h3>
              {new Intl.NumberFormat("en-AU", {
                style: "currency",
                currency: "AUD",
                minimumFractionDigits: 0
              }).format(price)}
            </h3>
            <h2>{title}</h2>
          </div>
          <div className="featured-thumbnail">
            {owner && owner !== "0" ? (
              <div className="sold-tag">
                <img src={soldTag} alt="SOLD" />
              </div>
            ) : null}

            {thumbnailimage ? (
              <PreviewCompatibleImage
                imageInfo={{
                  image: thumbnailimage,
                  alt: `featured image thumbnail for post ${title}`
                }}
              />
            ) : null}
          </div>
        </div>
        <div className="bottom-ribbon">
          <div className="stats">
            <div className="lvl">
              <div>Lvl&nbsp;{level}</div>
            </div>

            <div className="sqm">
              <div className="svg-wrap">
                <img src={sqmIcon} alt="sqm icon" />
              </div>

              <div>{sqm}sqm</div>
            </div>

            <div className="bedetc">
              <div className="bth-count">{bathrooms}</div>
              <div className="svg-wrap bth-icon">
                <img src={bathIcon} alt="bathroom icon" />
              </div>

              <div>{wildcardcount}</div>
              <div className="svg-wrap">
                {wildcardicon && (
                  <img
                    src={`/img/${wildcardicon.relativePath}`}
                    alt="wildcard icon"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

LevelCard.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export default LevelCard;
