import React from "react";
import PropTypes from "prop-types";
import lodashGet from "lodash.get";

import LevelCard from "./Card";

import "./style.sass";

const LevelRoll = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;

  if (!Array.isArray(posts)) {
    return null;
  }

  return (
    <div className="level-roll">
      {posts &&
        posts.map(({ node: post }, i) => {
          const {
            frontmatter: { price }
          } = post;

          const prevPrice = lodashGet(
            posts,
            `[${i - 1}].node.frontmatter.price`
          );
          return (
            <LevelCard
              key={post.id}
              post={post}
              marker={price !== prevPrice ? price : null}
            />
          );
        })}
    </div>
  );
};

LevelRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export default LevelRoll;
